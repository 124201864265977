<!--
 * @Author: Scarlett Xiao
 * @Date: 2021-10-18 10:21:21
 * @LastEditors: Scarlett Xiao
 * @LastEditTime: 2021-10-18 19:49:39
-->
<template>
  <div class="login-page"></div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    this.$axios({
      method: 'post',
      url: '/zhanjiang-admin/systemUserInfo/login',
      data: { userName: '18520052054', password: '123456' },
    })
      .then((res) => {
        if (res.code === '00000') {
          console.log('data', res.data)
          let sessionId = res.data.accessToken
          let userinfo = res.data.userinfo

          localStorage.setItem('sessionId', sessionId)

          let url = this.$route.query.url
          this.$router.replace(url ? `/${this.$route.query.url}` : '/')
        }
      })
      .catch(() => {})
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
</style>
